"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Navigation {
    constructor(elementId, hamburgerId) {
        var _a;
        this.navigationClassOpen = 'navbar__menu--open';
        this.hamburgerClassActive = 'hamburger--active';
        this.bodyClassNavigationOpen = 'body--navigation-open';
        this.navigation = document.querySelector(elementId);
        this.hamburger = document.querySelector(hamburgerId);
        this.links = (_a = this.navigation) === null || _a === void 0 ? void 0 : _a.querySelectorAll('a');
    }
    init() {
        var _a, _b;
        (_a = this.hamburger) === null || _a === void 0 ? void 0 : _a.addEventListener('click', this.toggleClasses.bind(this));
        (_b = Array.from(this.links)) === null || _b === void 0 ? void 0 : _b.forEach((link) => {
            link.addEventListener('click', this.removeClasses.bind(this));
        });
    }
    getNavigation() {
        return this.navigation;
    }
    getHamburger() {
        return this.hamburger;
    }
    getLinks() {
        return this.links;
    }
    getNavigationClassActive() {
        return this.navigationClassOpen;
    }
    getHamburgerClassActive() {
        return this.hamburgerClassActive;
    }
    getBodyClassNavigationOpen() {
        return this.bodyClassNavigationOpen;
    }
    toggleClasses(event) {
        event.stopImmediatePropagation();
        document.body.classList.toggle(this.bodyClassNavigationOpen);
        this.navigation.classList.toggle(this.navigationClassOpen);
        this.hamburger.classList.toggle(this.hamburgerClassActive);
    }
    removeClasses(event) {
        event.stopImmediatePropagation();
        document.body.classList.remove(this.bodyClassNavigationOpen);
        this.navigation.classList.remove(this.navigationClassOpen);
        this.hamburger.classList.remove(this.hamburgerClassActive);
    }
}
exports.default = Navigation;
