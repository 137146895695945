"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
class Form {
    constructor(formId, email) {
        this.formClassInvalid = 'form--invalid';
        this.fieldTextClassInvalid = 'text--error';
        this.fieldTextClassHidden = 'text--hidden';
        this.form = document.querySelector(formId);
        this.fields = this.form.querySelectorAll('input, textarea');
        this.email = email;
    }
    init() {
        const form = this.getForm();
        form.addEventListener('input', () => this.validate());
        form.addEventListener('submit', (event) => __awaiter(this, void 0, void 0, function* () {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.validate();
            if (!this.isValid())
                return;
            yield this.onSubmit(form);
        }));
    }
    getEmail() {
        return this.email;
    }
    getForm() {
        return this.form;
    }
    getFields() {
        return this.fields;
    }
    getFormClassInvalid() {
        return this.formClassInvalid;
    }
    getFieldTextClassInvalid() {
        return this.fieldTextClassInvalid;
    }
    getFieldTextClassHidden() {
        return this.fieldTextClassHidden;
    }
    validate() {
        const fields = this.getFields();
        this.getForm().classList.toggle(this.getFormClassInvalid(), !this.getForm().checkValidity());
        Array.from(fields).forEach((field) => {
            var _a;
            const text = (_a = field.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector(`[date-validate-for="${field.getAttribute('id')}"]`);
            text === null || text === void 0 ? void 0 : text.classList.remove(this.getFieldTextClassInvalid());
            text === null || text === void 0 ? void 0 : text.classList.add(this.getFieldTextClassHidden());
            if (field.checkValidity())
                return;
            text === null || text === void 0 ? void 0 : text.classList.add(this.getFieldTextClassInvalid());
            text === null || text === void 0 ? void 0 : text.classList.remove(this.getFieldTextClassHidden());
        });
    }
    isValid() {
        return this.getForm().querySelectorAll(`.${this.getFieldTextClassInvalid()}`).length === 0;
    }
    onSubmit(form) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const name = form.querySelector('#contact-name').value.trim();
            const lastName = form.querySelector('#contact-lastname').value.trim();
            const email = form.querySelector('#contact-email').value.trim();
            const message = form.querySelector('#contact-message').value.trim();
            let hiddenContactSection;
            try {
                yield this.email.send({
                    name,
                    lastName,
                    email,
                    message,
                });
                hiddenContactSection = document.querySelector('#contact-success');
            }
            catch (error) {
                hiddenContactSection = document.querySelector('#contact-error');
            }
            (_a = form.closest('#contact')) === null || _a === void 0 ? void 0 : _a.remove();
            hiddenContactSection.classList.remove('contact--hidden');
            hiddenContactSection.setAttribute('id', 'contact');
        });
    }
    reset() {
        const form = this.getForm();
        form.classList.remove(this.getFormClassInvalid());
        const texts = form.querySelectorAll('[date-validate-for]');
        Array.from(texts).forEach((text) => {
            text.classList.remove(this.getFieldTextClassInvalid());
            text.classList.add(this.getFieldTextClassHidden());
        });
    }
}
exports.default = Form;
